import { WAREHOUSE_META } from '../../meta/main/warehouse'

export default [
  {
    path: 'warehouse',
    name: 'home-warehouse',
    component: () => import('@/views/main/warehouse/main-order/Warehouse.vue'),
    redirect: { name: 'home-warehouse-view' },
    children: [
      {
        path: 'warehouse-page',
        name: 'home-warehouse-view',
        component: () => import('@/views/main/warehouse/main-order/view/WarehouseView.vue'),
        layout: 'Home Warehouse',
        meta: WAREHOUSE_META,
        redirect: { name: 'home-warehouse-week' },
        children: [
          {
            path: 'week',
            name: 'home-warehouse-week',
            component: () => import('@/views/main/warehouse/main-order/view/week/Week.vue'),
            layout: 'Home Warehouse Week',
            meta: WAREHOUSE_META,
          },
          {
            path: 'list',
            name: 'home-warehouse-list',
            component: () => import('@/views/main/warehouse/main-order/view/list/List.vue'),
            layout: 'Home Warehouse List',
            meta: WAREHOUSE_META,
            redirect: { name: 'home-warehouse-by-products' },
            children: [
              {
                path: 'by-products',
                name: 'home-warehouse-by-products',
                component: () => import('@/views/main/warehouse/main-order/components/tables/WeekListByProducts.vue'),
                layout: 'Home Warehouse List By Products',
                meta: WAREHOUSE_META,
              },
              {
                path: 'by-orders',
                name: 'home-warehouse-by-orders',
                component: () => import('@/views/main/warehouse/main-order/components/tables/WeekListByOrders.vue'),
                layout: 'Home Warehouse List By Orders',
                meta: WAREHOUSE_META,
              },
            ],
          },
        ],
      },
      {
        path: 'transfer-pick/:id',
        name: 'warehouse-transfer-pick',
        component: () => import('@/views/main/warehouse/view/pick/transfer/Transfer.vue'),
        layout: 'Home Warehouse',
        meta: WAREHOUSE_META,
      },
      {
        path: 'rental-sales-pick/:id',
        name: 'warehouse-rental-sales-pick',
        component: () => import('@/views/main/warehouse/view/pick/rental-sales/RentalSales.vue'),
        layout: 'Home Warehouse',
        meta: WAREHOUSE_META,
      },
      {
        path: 'service-order/:id',
        name: 'warehouse-service-order',
        component: () => import('@/views/main/warehouse/view/pick/service-order/ServiceOrderPick.vue'),
        layout: 'Home Warehouse',
        meta: WAREHOUSE_META,
      },
      {
        path: 'pack-order/:id/:orderType',
        name: 'home-warehouse-pack-order',
        component: () => import('@/views/main/warehouse/view/pack/create/PackCreate.vue'),
        layout: 'Home Warehouse Pack',
        meta: WAREHOUSE_META,
      },
      {
        path: 'dispatch-order/:id/:orderType',
        name: 'dispatch-order',
        component: () => import('@/views/main/warehouse/view/dispatch/DispatchOrder.vue'),
        layout: 'Dispatch Order',
        meta: WAREHOUSE_META,
      },
      {
        path: 'dispatch-order/:id/:orderType/signature',
        name: 'dispatch-order-signature',
        component: () => import('@/views/main/warehouse/view/dispatch/dispatch-signature/Signature.vue'),
        layout: 'Dispatch Order Signature',
        meta: WAREHOUSE_META,
      },
      {
        path: 'inbound/:orderType/:id',
        name: 'inbound-orders',
        component: () => import('@/views/main/warehouse/view/receive/InBoundOrder.vue'),
        layout: 'Receive Order',
        meta: WAREHOUSE_META,
      },
    ],
  },
]
