import axios from '@axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { stringify } from 'qs'
import { customActionDel } from '@core/utils/utils'

const get = (url, commit, commitKey, responseKey = null, queryParams = {}) => new Promise((resolve, reject) => {
  axios.get(url, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      const { data } = response
      commit(commitKey, responseKey ? data.data[responseKey] : data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
export default {
  brandsList({ commit }, queryParams) {
    return get('/brands', commit, 'SET_BRANDS_LIST', 'data', { order_type: 'desc', ...queryParams })
  },
  categoriesList({ commit }, queryParams) {
    const baseQuery = {
      order_column: 'id',
      order_type: 'desc',
    }

    return get('/categories', commit, 'SET_CATEGORIES_LIST', 'data', { ...baseQuery, ...queryParams })
  },
  roleList({ commit }, queryParams) {
    return get('/roles', commit, 'SET_ROLE_LIST', 'data', queryParams)
  },
  containerType({ commit }, queryParams) {
    return get('/container-types', commit, 'SET_CONTAINER_TYPE_LIST', false, queryParams)
  },
  warehouseList({ commit }, queryParams) {
    return get('/settings/warehouses', commit, 'SET_WAREHOUSE_LIST', 'data', { ...queryParams, status: 1 })
  },
  permissionList({ commit }, queryParams) {
    return get('/permissions', commit, 'SET_PERMISSION_LIST', null, queryParams)
  },
  notificationList({ commit }, queryParams) {
    return get('/settings/notification/trigger', commit, 'SET_NOTIFICATION_LIST', null, queryParams)
  },
  settingNotificationList({ commit }, queryParams) {
    return get('/settings/notification', commit, 'SET_SETTING_NOTIFICATION_LIST', null, queryParams)
  },
  currencyList({ commit }, queryParams) {
    return get('/currencies', commit, 'SET_CURRENCY_LIST', 'data', queryParams)
  },
  paymentTermList({ commit }, queryParams) {
    return get('/payment-terms', commit, 'SET_PAYMENT_TERM_LIST', 'data', queryParams)
  },
  rentalOrderList({ commit }, queryParams) {
    return get('/orders/rental_sale', commit, 'SET_RENTAL_ORDER_LIST', 'data', {
      is_active: true,
      is_rental: true,
    })
  },
  priceTierList({ commit }, queryParams) {
    return get('/price-tiers', commit, 'SET_PRICE_TIER_LIST', null, queryParams)
  },
  taxRuleList({ commit }, queryParams) {
    return get('/tax-rules', commit, 'SET_TAX_RULE_LIST', 'data', queryParams)
  },
  accountManagerList({ commit }, queryParams) {
    return get('/users', commit, 'SET_ACCOUNT_MANAGER_LIST', 'data', queryParams)
  },
  orderDispatchList({ commit }, queryParams) {
    const { parentValue: { id } } = queryParams
    return get('/users', commit, 'SET_ORDERS_DISPATCH_LIST', 'data', { warehouse_id: id })
  },
  orderPlacedList({ commit }, queryParams) {
    return get('/users', commit, 'SET_ORDERS_PLACED_BY_LIST', 'data', queryParams)
  },
  orderDispatchContactList({ commit }, queryParams) {
    if (!queryParams) return false
    const { parentValue } = queryParams
    return get(`/customers/${parentValue}/contacts`, commit, 'SET_ORDER_DISPATCH_CONTACT_LIST', 'data', { ...queryParams, billing: 1 })
  },
  dispatchAndReturnMethodsList({ commit }, queryParams) {
    return get('/settings/fulfillment/dispatches', commit, 'SET_ORDER_DISPATCH__RETURN_METHODS_LIST', 'data', queryParams)
  },
  deliveryTypeList({ commit }, queryParams) {
    return get('/delivery-types', commit, 'SET_DELIVERY_TYPE_LIST', 'data', queryParams)
  },
  countryList({ commit }, queryParams) {
    return get('/countries', commit, 'SET_COUNTRY_LIST', null, queryParams)
  },
  stateList({ commit }, queryParams) {
    return get(`/countries/${queryParams.parentValue}/states`, commit, 'SET_STATE_LIST', null, queryParams)
  },
  statusList(ctx, queryParams) {
    return queryParams
  },
  monthListInText(ctx, queryParams) {
    return queryParams
  },
  status01List(ctx, queryParams) {
    return queryParams
  },
  levelList(ctx, queryParams) {
    return queryParams
  },
  attachmentCategoryListVerification({ commit }) {
    return get('/system/attachment-categories', commit, 'SET_ATTACHMENT_CATEGORY_LIST_VERIFICATION', null, { type: 'verification' })
  },
  attachmentCategoryListContact({ commit }) {
    return get('/system/attachment-categories', commit, 'SET_ATTACHMENT_CATEGORY_LIST_CONTACT', null, { type: 'accounts' })
  },
  attachmentCategoryListCatalog({ commit }) {
    return get('/system/attachment-categories', commit, 'SET_ATTACHMENT_CATEGORY_LIST_CATALOG', null, { type: 'catalog' })
  },
  attachmentCategoryListAssets({ commit }) {
    return get('/system/attachment-categories', commit, 'SET_ATTACHMENT_CATEGORY_LIST_ASSETS', null, { type: 'assets' })
  },
  attachmentCategoryListInventoryAssetDamaged({ commit }) {
    return get('/system/attachment-categories', commit, 'SET_ATTACHMENT_CATEGORY_LIST_ASSETS_DAMAGED', null, { type: 'inventory_item_damaged' })
  },
  timezoneList({ commit }) {
    return get('/time-zones', commit, 'SET_TIMEZONE_LIST')
  },
  dateformatList({ commit }, queryParams) {
    return get('/date-formats', commit, 'SET_DATEFORMAT_LIST', 'data', queryParams)
  },
  measurementUnitsList({ commit }) {
    return get('/measurement-units', commit, 'SET_MEASUREMENT_UNITS_LIST', 'data', { order_type: 'desc' })
  },
  measurementCategoriesList({ commit }) {
    return get('/measurement-categories', commit, 'SET_MEASUREMENT_CATEGORIES_LIST', 'data', { order_type: 'desc' })
  },
  sendEmailFromList(ctx, queryParams) {
    return queryParams
  },
  exportFileEncodingList(ctx, queryParams) {
    return queryParams
  },
  documentIdList(ctx, queryParams) {
    return queryParams
  },
  languageList({ commit }, queryParams) {
    return get('/system/languages', commit, 'SET_LANGUAGES_LIST', 'data', queryParams)
  },
  settingsDateFormat({ commit }, queryParams) {
    return get('/settings/general/organization', commit, 'SET_SETTINGS_DATE_FORMAT', 'date_format', queryParams)
  },
  settingsCatalogPricingRules({ commit }, queryParams) {
    return get('/settings/catalog/pricing-rules', commit, 'SETTINGS_CATALOG_PRICING_RULES', null, queryParams)
  },
  paymentOptionList({ commit }, queryParams) {
    return get('/payment-methods', commit, 'SET_PAYMENT_OPTIONS', 'data', queryParams)
  },
  customerRulesList({ commit }, queryParams) {
    return get('/customer-rules', commit, 'SET_CUSTOMER_RULES', '', queryParams)
  },
  organizationData({ commit }) {
    return get('/settings/general/organization', commit, 'SET_ORGANIZATION_DATA', null)
  },
  tagsList({ commit }, queryParams) {
    return get('/tags', commit, 'SET_TAGS_DATA', 'data', queryParams)
  },
  productTypesList({ commit }, queryParams) {
    return get('/products/types', commit, 'SET_PRODUCT_TYPES_DATA', 'data', queryParams)
  },
  pricingTypesList({ commit }) {
    return get('/pricing/types', commit, 'SET_PRICING_TYPES_DATA', 'data', { order_type: 'desc' })
  },
  pricingRulesList({ commit }) {
    return get('/pricing/rules', commit, 'SET_PRICING_RULES_DATA', 'data', { order_type: 'desc' })
  },
  warehouseLocationsList({ commit }, queryParams) {
    return get('/settings/warehouse-locations', commit, 'SET_WAREHOUSE_LOCATION_DATA', 'data', queryParams)
  },
  pricingRulesEnumList({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/settings/catalog/pricing/rules').then(response => {
        const { data } = response.data
        Object.keys(data).forEach(key => {
          const commitKey = `settings_pricing_rules_${key}`.toUpperCase()
          commit(commitKey, data[key])
        })
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  catalogPricingRulesList({ commit }) {
    return get('/settings/catalog/pricing-rules', commit, 'SET_CATALOG_PRICING_RULES')
  },
  fullfilBarcodeScannerList({ commit }) {
    return get('/barcode-scanner-types', commit, 'SET_SETTING_BARCODE_SCANNER_LIST', null)
  },
  unitTypeList({ commit }) {
    return get('/products/unit-types/list', commit, 'SET_PRODUCT_UNIT_TYPE_LIST')
  },
  supplierList({ commit }, queryParams) {
    return get('customers?f[allow_to_purchase_from_supplier]=1', commit, 'SET_SUPPLIER_LIST', 'data', queryParams)
  },
  customersList({ commit }, queryParams) {
    return get('/customers', commit, 'CUSTOMERS_LIST', 'data', queryParams)
  },
  insuranceCompanyNameList({ commit }) {
    return get('/customers/insurance-companies', commit, 'SET_INSURANCE_COMPANY_NAME_LIST')
  },
  assetConditionList({ commit }) {
    return get('/settings/inventory/asset-condition-taxonomies', commit, 'SET_ASSET_CONDITION_LIST', 'data')
  },
  operationHoursDisableList(ctx, queryParams = {}) {
    const warehouseId = ctx.rootState.app.warehouse?.id
    ctx.commit('SET_IS_LOADING_OPERATION_HOURS_DISABLE_LIST', true)
    return get('/system/operation-hours', ctx.commit, 'SET_OPERATION_HOURS_DISABLE_LIST', null, { ...queryParams, warehouse_id: warehouseId })
  },
  billingContact({ commit }, queryParams = {}) {
    return get(`/customers/${queryParams.parentValue}/contacts`, commit, 'SET_BILLING_CONTACT_LIST', 'data', { ...queryParams, billing: 1 })
  },
  shippingContact({ commit }, queryParams = {}) {
    return get(`/customers/${queryParams.parentValue}/contacts`, commit, 'SET_SHIPPING_CONTACT_LIST', 'data', { ...queryParams, shipping: 1 })
  },
  dispatchAndReturnAddress({ commit }, queryParams = {}) {
    return get(`/customers/${queryParams.parentValue}/addresses`, commit, 'SET_DISPATCH_AND_RETURN_ADDRESS_LIST', 'data', { ...queryParams })
  },
  billingContactOnPurchase({ commit }, queryParams) {
    return get(`/customers/${queryParams.parentValue}/contacts`, commit, 'SET_BILLING_CONTACT_ON_PURCHASE_LIST', 'data', { ...queryParams, billing: 1 })
  },
  warehousesAddress({ commit }, queryParams) {
    return get('/settings/warehouses', commit, 'SET_WAREHOUSES_ADDRESS', 'data', { ...queryParams })
  },
  ecommerceShippingMethod({ commit }, queryParams) {
    return get('/ecommerce-shipping-methods', commit, 'ECOMMERCE_SHIPPING_METHOD', '', { ...queryParams })
  },
  integrationsList({ commit }, queryParams) {
    return get('/payment-gateways', commit, 'SET_PAYMENT_GATEWAYS', 'data', queryParams)
  },
  deleteAttachment(_, { id, queryParams }) { customActionDel(`attachments/${id}`, queryParams) },

}
